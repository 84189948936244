@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Open Sans" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* @media (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
} */
.slick-next:before, .slick-prev:before {
  color: black !important; 
}

.cardsize {
  height:100%;
  width: 100%;
}

@media only screen and (max-width: 550px) {
  .slick-next:before, .slick-prev:before {
    display: none !important;
  }
}


.card-text {
  max-height: 4.8em; /* Approx. 4 lines based on 1.2em line height */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* -webkit-line-clamp: 4; */
  -webkit-box-orient: vertical;
  transition: max-height 0.3s ease;
}

.card-text.expanded {
  max-height: none;
}
